<template>
  <div class="main">
    

    <el-dialog title="新建" :visible.sync="dialogVisible">
      <el-form :model="form" label-position="top">
        <el-form-item label="用户名">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="关联网点">
          <el-select v-model="form.storeGuid" style="width:100%">
            <el-option
              v-for="item in storeList"
              :key="item.guid"
              :label="item.name"
              :value="item.guid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="Add">确 定</el-button>
      </div>
    </el-dialog>


    <el-card>
      <div>
        <el-button type="primary" @click="Create" size="mini" style="margin-bottom:20px">新建</el-button>
        <el-table :data="tableData" style="width: 100%" size="mini" border>
          <el-table-column prop="account" label="用户名" ></el-table-column>
          <el-table-column label="绑定" >
            <template slot-scope="{row}">{{row.bind}}</template>
          </el-table-column>
          <el-table-column prop="guid" label="guid" ></el-table-column>
          <el-table-column prop="storeName" label="网点" ></el-table-column>
          <el-table-column width="400px">
            <template slot-scope="{row}">
              <el-button size="mini" type="primary" @click="QrCode(row.guid)">获取绑定二维码</el-button>
              <el-button size="mini" type="danger" @click="Unbind(row.guid)">解绑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data(){
    return {
      dialogVisible:false,
      tableData:[],
      storeList:[],
      form:{
        account:null,
        storeGuid:null,
      }
    }
  },
  mounted(){
    this.Init();
    this.Store();
  },
  methods:{
    Create(){
      this.form = { account:null,storeGuid:null };
      this.dialogVisible = true;
    },
    Init(){
      this.$axios.get('/api/admin/list')
      .then(res => {
        this.tableData = res;
      })
    },
    Store(){
      this.$axios.get('/api/store/list')
      .then(res => {
        this.storeList = res;
      })
    },
    QrCode(guid){
      this.$axios.get(`/api/admin/qrCode?guid=${guid}`).then(res=>{
        this.$alert(`<img style="width: 200px; height: 200px" src="${res}"></img>`, '绑定二维码', {
          dangerouslyUseHTMLString: true
        });
      })
    },
    Unbind(guid){
      this.$axios.post(`/api/admin/unbind`,JSON.stringify({adminGuid:guid}))
      .then(() => {
        this.Init()
      })
    },
    Add(){
      this.$axios.post(`/api/admin/add`,JSON.stringify(this.form))
      .then((res) => {
        console.log(res);
        this.form = { account:null,storeGuid:null };
        this.dialogVisible = true;
        this.Init()
      })
    }
  }

}
</script>
<style>
 .main{
   text-align: left;
 }
</style>