import axios from "axios";
import {Notification, Message} from 'element-ui';
import router from '../router'

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例 axios.create([config])
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
});
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers.token = localStorage.getItem('token')
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    // 获取错误信息
    const msg = response.data.msg;
    if (code == 500) {
      Message({ message: msg, type: 'error' });
      return Promise.reject(new Error(msg));
    } else if (code != 200) {
      Notification.error({ title: msg });
      return Promise.reject('error');
    } else{
      return response.data.data
    }
  },
  error => {
    let {message} = error;
    console.log(message);
    if (message.includes('401')) {
      router.replace('/')
      return;
    }else if (message == 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 });
    return Promise.reject(error);
  }
);
service.all = axios.all
service.spread = axios.spread
export default service;